import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import web5 from "../img/restaur.jpg";
import "../style/styles.css";

const Restaurant = () => {
  let alText="Photo";
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "60vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
        </Container>
        <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>RestaurSof</h2>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Software for GST and Touched Screen Enabled Restaurant and Pubs and also without touched version available
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ textAlign: "justify" }}>
            <p>
            RestaurSof - A restaurant management software is used to systematically manage restaurant operations. For a dine-in restaurant this includes order taking, kitchen order tickets (KOTs), billing, reporting, inventory, recipe management,GST enabled reports and Financial Accounting.
            <p style={{ fontWeight:"Bold" }} >We’ve introduced a QR code menu system, enabling customers to order food directly from their table via smartphone for a contactless dining experience.</p>
            </p>

            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Price(1 licence) : Rs. 15,000/- Multi-User+ GST
              <br></br>
             
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container">
              <div className="row">
                
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Key features(Entry Module)
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                    <li>KOT /BOT</li>
                    <li>KOT/BOT Billing</li>
                    <li>KOT and KOT Billing from Android App </li>
                    <li>Room Service</li>
                    <li>Raw Material Purchase</li>
                    <li>Purchase Return</li>
                    <li>Store Issue</li>
                    <li>Department Indent</li>
                    <li>Purchase Order</li>
                    <li>Food Menu Receipe Analysis</li>
                    <li>Food Costing Configuration</li>
                    <li>Order Generating on order Level</li>




                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    MIS Reports
                  </div>
                  <div className="item-holder">
                  <ul className="checked-list">
                  <li> KOT Register</li>
                  <li> Void KOT Register</li>
                  <li> NC KOT Register</li>
                  <li> Product sales Register Date Wise</li> 
                  <li> Sales Register (Including GST)</li>
                  <li> GST Rate Wise Sales Register</li>
                  <li> Payment Type Wise Sales and Collection Register</li>
                  <li> Restaurant Night Audit Report</li>
                  <li> Department Wise Restaurant Sales Report</li>
                  <li> Shift Wise Restaurant Sales Report</li>
                  <li> Complimentory Restaurant Sales Report</li>
                  <li> Waiter Wise Restaurant Sales Report</li>
                  <li> Table Wise Restaurant Sales Report</li>
                  <li> Graphical Report for Visitors Analysis </li>
                  
                    </ul>
                  </div>
                </Card>
              </div>
            </div>

            <br></br>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container">
              <div className="row">
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Stock Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                   <li> Store Raw Material Purchase</li>
                   <li>Issue Raw Material to Various Department</li>
                   <li>Analysis of Finished Food</li>
                   <li>Raw Material Stock (Kitchen or Department Wise)</li>
                   <li>Wine and Beverage Stock (Store and Department Wise)</li>
                   <li>Store Stock (Over all)</li>
                   <li>Store Consumption Analysis</li>
                   <li>Store Purchase Analysis</li>
                   <li>Indent Generating</li>
                   <li>Purchase Order Register</li>
                   <li>Pending Indent</li>
                   <li>Food Costing</li>
                   
</ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    MIS Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Supplier Wise Product Purchase Register</li>
                      <li>Supplier Wise Monthly Product Purchase Report</li>
                      <li>Department Wise Store Consumption Reports</li>
                      <li>Pending Purchase Order Register</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Accounting Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Cash Bank Book</li>
                      <li>Journal Book</li>
                      <li>Debit Note Register</li>
                      <li>Credit Note Register</li>
                      <li>Purchase Register</li>
                      <li>Purchase Return Register</li>
                      <li>Sales Register</li>
                      <li>Sales Return Register</li>
                      <li>Ledger</li>
                      <li>Trial Balance</li>
                      <li>Expense Trial</li>
                      <li>Trading and Profit & Loss Statement</li>
                      <li>Bank Reconciliation</li>
                      <li>Interest Calculation</li>
                      <li>Confirmation of Accounts</li>
                      <li>Statement of Expenditure</li>
                      <li>Cost Centre Reports</li>
                      <li>GST Type Wise Sales Register</li>
                      <li>GSTR(GSTR-I,GSTR-II,GSTR3B) Reports</li>
                    </ul>
                  </div>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Restaurant;
