// EmailContactForm.js
import React, { useState } from "react";
import axios from "axios";
import { Form, Input, TextArea, Button, Container, Header, Message } from "semantic-ui-react";
import Swal from "sweetalert2";
import "../style/styles.css";

const EmailContactForm = () => {
    const [to, setTo] = useState('');
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateEmail(to)) {
            Swal.fire({
                icon: "warning",
                title: "Invalid Email",
                text: "Please enter a valid email address.",
            });
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post('https://api.ebssoftware.co.in/send-email', {
                to,
                subject,
                text,
            });
            Swal.fire({
                icon: "success",
                title: "Message Sent Successfully",
                text: response.data,
            });
            setTo('');
            setSubject('');
            setText('');
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops, something went wrong",
                text: error.response ? error.response.data : error.message,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container className="EmailContactForm" text>
            <Header as="h2" textAlign="center" style={{ margin: '20px 0' }}>
                Contact Us
            </Header>
            <Form onSubmit={handleOnSubmit} unstackable>
                <Form.Field
                    control={Input}
                    label='Email'
                    name='user_email'
                    placeholder='Your Email…'
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    required
                    icon='mail'
                    iconPosition='left'
                />
                <Form.Field
                    control={Input}
                    label='Subject'
                    name='user_subject'
                    placeholder='Subject…'
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                />
                <Form.Field
                    control={TextArea}
                    label='Message'
                    name='user_message'
                    placeholder='Your Message…'
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    required
                    style={{ minHeight: 100 }}
                />
                <Button type='submit' color='green' fluid loading={loading}>
                    {loading ? 'Sending...' : 'Submit'}
                </Button>
            </Form>
            <Message
                attached
                header='We would love to hear from you!'
                content="Your feedback is important to us. Please fill out the form above."
                style={{ marginTop: '20px' }}
            />
        </Container>
    );
}

export default EmailContactForm;
